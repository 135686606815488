import React from "react";
import styled from "styled-components";

const ServiceWrapper = styled.div`
  display: block;
  flex-direction: column;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

const ServiceSection = styled.div`
  flex: 1;
  padding: 0 20px;

  @media (max-width: 900px) {
    padding: 30px 10px;
  }
`;

const ServiceTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const ServiceText = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

const ServiceImage = styled.img`
  max-width: 100%;
  height: 300px;
  border-radius: 15px;
  @media (max-width: 900px) {
    max-width: 100%;
    height: auto;
  }
`;

const OptionText = styled.span`
  font-size: 16px;
`;

const Press = ({ title, text, buttonText, imageUrl, title2, options }) => {
  return (
    <ServiceWrapper>
      <div className="service-sec1">
        <ServiceSection>
          <ServiceTitle>{title}</ServiceTitle>
          <ServiceText>{text}</ServiceText>
          <button className="primary-button">{buttonText}</button>
        </ServiceSection>
        <ServiceSection>
          <ServiceImage src={imageUrl} alt="Service" />
        </ServiceSection>
      </div>
      <div className="service-sec2">
        <ServiceSection>
          <h3>{title2}</h3>
        </ServiceSection>
        <ServiceSection>
          {options.map((option, index) => (
            <div className="service-option" key={index}>
              <OptionText>{option}</OptionText>
            </div>
          ))}
        </ServiceSection>
      </div>
    </ServiceWrapper>
  );
};

export default Press;
