import React from "react";
import { motion } from "framer-motion";
import YouTube from "react-youtube";

const HSection = () => {
  // Replace with your actual YouTube video ID
  const videoId = "fv90KEbzHTQ";

  return (
    <div className="h-section-container">
      <motion.div
        className="video-container"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        <YouTube videoId={videoId} />
      </motion.div>

      <motion.div className="text-container">
        <motion.h2
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          QUI SOMMES-NOUS?
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.5 }}
        >
          Bienvenue chez Groupe COLLABLE, une entreprise algérienne établie à
          Béjaïa. Notre expertise, dirigée par une équipe compétente, couvre les
          secteurs du service client, de la technologie, des services
          financiers, de la vente, du télémarketing et des ressources humaines.
          Réputés pour notre flexibilité, nos équipes qualifiées et nos
          infrastructures avancées, nous offrons un service de qualité
          supérieure grâce à notre engagement à long terme et à notre expérience
          diversifiée. En tant que centre créé pour les agents, notre position
          géographique renforce notre efficacité et notre professionnalisme pour
          répondre aux besoins de nos clients.
        </motion.p>
        <motion.button
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.7, delay: 0.8 }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="secondary-button"
          onClick={() =>
            window.open(
              "https://www.youtube.com/channel/UCWk835yEtHxvh-BnMtUK9RQ",
              "_blank"
            )
          }
        >
          Voir Plus
        </motion.button>
      </motion.div>
    </div>
  );
};

export default HSection;
