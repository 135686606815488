import React from "react";
import Man1 from "../Assets/images/yacine.jpg";
import Man2 from "../Assets/images/farid.jfif";
import Man3 from "../Assets/images/amel.jpg";
import Man4 from "../Assets/images/adel.jpeg";
import Man5 from "../Assets/images/Ryma.jpeg";
import "./Team.css";
const Team = () => {
  const teamInfoData = [
    {
      image: Man2,
      title: "Farid HAMIDOU",
      text: "Farid est un visionnaire avec une capacité remarquable à communiquer des idées efficacement. Associée à une éthique de travail inégalée.",
      job: "Directeur Support Technique",
    },
    {
      image: Man3,
      title: "Ghania LAIB",
      text: "Dans l'esprit de la jeunesse, Amel excelle en tant qu'apprenant rapide, naviguant avec soin et précision à travers les défis.",
      job: "Directrice Ressources Humaine",
    },
    {
      image: Man4,
      title: "Adel AIT MOUHOUB",
      text: "Avec un esprit ouvert et une sagesse calculée, Adel manœuvre habilement à travers les complexités, apportant calme et précision à nos projets.",
      job: " Directeur Service Financier",
    },
    {
      image: Man5,
      title: "Ryma BELKASMI",
      text: "Insufflant une énergie positive dans chaque projet, Ryma se présente comme un phare de transparence et d'honnêteté. Sa présence constante garantit un environnement de travail prospère basé sur la confiance et l'intégrité.",
      job: "Directrice Service Client",
    },
  ];

  return (
    <div className="team-container">
      <div className="work-section-top">
        <h1 className="body-heading">Notre Équipe</h1>
      </div>

      <div className="ceo-container">
        <div className="team-member">
          <img className="team-member-img" src={Man1} alt="" />
          <div className="team-member-info">
            <h2 className="team-member-title">Yacine AKSIL </h2>

            <h3 className="team-member-job">CEO</h3>
          </div>
        </div>
      </div>

      <div class="parent">
        <div class="el">
          <div class="left"></div>
          <div class="right"></div>
        </div>
      </div>
      <div className="team-chart">
        {teamInfoData.map((data) => (
          <div className="team-member" key={data.title}>
            <img className="team-member-img" src={data.image} alt="" />
            <div className="team-member-info">
              <h2 className="team-member-title">{data.title}</h2>

              <h3 className="team-member-job">{data.job}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;

/*
import React from "react";
import Man1 from "../Assets/images/yacine.jpg";
import Man2 from "../Assets/images/farid.jfif";
import Man3 from "../Assets/images/amel.jpg";
import Man4 from "../Assets/images/adel.jpg";
import Man5 from "../Assets/images/Ryma.jpeg";

const Team = () => {
  const teamInfoData = [
    {
      image: Man1,
      title: "AKSIL YACINE",
      text: "Yacine dirige avec vision, ambition et une détermination inébranlable, transformant l'impossible en opportunités de succès.",
      job: "CEO",
    },
    {
      image: Man2,
      title: "FARID HAMIDOU",
      text: "Farid est un visionnaire avec une capacité remarquable à communiquer des idées efficacement. Associée à une éthique de travail inégalée.",
      job: "Project Manager",
    },
    {
      image: Man3,
      title: "Amel",
      text: "Dans l'esprit de la jeunesse, Amel excelle en tant qu'apprenant rapide, naviguant avec soin et précision à travers les défis.",
      job: "Human Resources",
    },
    {
      image: Man4,
      title: "Adel",
      text: "Avec un esprit ouvert et une sagesse calculée, Adel manœuvre habilement à travers les complexités, apportant calme et précision à nos projets.",
      job: "Director of Sales",
    },
    {
      image: Man5,
      title: "Ryma",
      text: "Insufflant une énergie positive dans chaque projet, Ryma se présente comme un phare de transparence et d'honnêteté. Sa présence constante garantit un environnement de travail prospère basé sur la confiance et l'intégrité.",
      job: "Communication Manager",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Notre Équipe</p>
        <h1 className="body-heading">Professionnel et Expérimenté</h1>
        <p className="body-text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod te incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim to eismud
        </p>
      </div>
      <div className="work-section-bottom">
        {teamInfoData.map((data) => (
          <div className="team-section-info" key={data.title}>
            <div className="team-img">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
            <h3>{data.job} </h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
*/
