import React from "react";
import Lottie from "react-lottie";
import animationData from "./animation.json"; // Replace with the path to your Lottie animation JSON file

const Waves = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="lottie-container">
      <Lottie className="lottie" options={defaultOptions} height={500} />
    </div>
  );
};

export default Waves;

/*
import React from "react";
import { LottiePlayer } from "@lottiefiles/lottie-player";
import "./Quote.css";

const Waves = () => {
  return (
    <div className="lottie-container">
      <LottiePlayer
        src="https://lottie.host/952d3f6e-edc6-4843-95c7-676ab9a22d74/FsasNbPQTP.json"
        background
        speed="1"
        style={{ width: "100%", height: "100%" }}
        direction="1"
        mode="normal"
        loop
        autoplay
      />
    </div>
  );
};

export default Waves;
*/
