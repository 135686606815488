import React from "react";
import ContactSection from "../ContactSection";
import PageTitle from "../PageTitle";

const Contact = () => {
  return (
    <div>
      <PageTitle title="Contact" />
      <ContactSection />
    </div>
  );
};

export default Contact;
