import React from "react";
import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

import CanvasLoader from "../Loader";

const Earth = () => {
  const earth = useGLTF("/planet/scene.gltf");

  return (
    <primitive object={earth.scene} scale={3} position-y={-1} rotation-y={0} />
  );
};

const Planet = () => {
  return (
    <Canvas
      colorManagement
      shadowMap
      camera={{ position: [0, 5, 15], fov: 50 }}
    >
      <ambientLight intensity={0.3} />
      <directionalLight
        castShadow
        position={[10, 10, 5]}
        intensity={1.5}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={50}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
      />
      <Suspense fallback={<CanvasLoader />}>
        <Earth />
        <Preload all />
      </Suspense>
      <OrbitControls
        enableZoom={false} // Disable zoom
        enablePan={false} // Disable grab
        autoRotate
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 2}
      />
    </Canvas>
  );
};

export default Planet;

/*
import React from "react";
import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";

import Model from "./Model";

const Planet = () => {
  return (
    <Canvas
      colorManagement
      shadowMap
      camera={{ position: [0, 5, 15], fov: 50 }}
    >
      <ambientLight intensity={0.3} />
      <directionalLight
        castShadow
        position={[10, 10, 5]}
        intensity={1.5}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={50}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
      />
      <Suspense fallback={null}>
        <Model />
      </Suspense>
      <OrbitControls />
    </Canvas>
  );
};

export default Planet;
*/
