import React from "react";

import Ser1 from "../Assets/sup1.jpg";
import Ser2 from "../Assets/sup2.jpg";
import Ser3 from "../Assets/sup3.jpg";
import Ser4 from "../Assets/sup5.jpg";

const Services = () => {
  const servicesData = [
    {
      image: Ser1,
      text: "Assistance multicanale 24/7. Gestion des plaintes et des retours. Formation continue du personnel ",
      link: 5,
      name: "Service Client",
    },
    {
      image: Ser2,
      text: "Expertise technique pointue dans une variété de domaines. Dépannage rapide et précis. Mise en place de solutions de suivi et de surveillance.",
      link: 5,
      name: "Support Technique",
    },
    {
      image: Ser3,
      text: "Recrutement stratégique et gestion RH. Développement professionnel continu. Tenue des livres précise et conforme aux normes locales et internationales",
      link: 5,
      name: "Gestion RH",
    },
    {
      image: Ser4,
      text: "Analyse financière approfondie. Assistance dans la planification budgétaire et fiscale. Comptabilité ",
      link: 5,
      name: "Services Financiers",
    },
  ];

  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <h1 className="body-heading">Nos Services</h1>
        <p className="body-text">
          Chez Collable, nous proposons une gamme complète de Services pour
          répondre à vos besoins Commerciaux les plus Exigeants.
        </p>
      </div>

      <div className="testimonial-slider">
        {servicesData.map((service, index) => (
          <div
            className="service-card"
            key={index}
            style={{
              backgroundImage: `url(${service.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="overlay"></div>
            <h2>{service.name}</h2>
            <ul>
              {service.text.split(". ").map((point, index) => (
                <li key={index}>{point}.</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
