import React, { useState, useEffect } from "react";
import styled from "styled-components";

import bg2 from "../Assets/images/wr2.jpg";
import bg3 from "../Assets/images/wr5.jpg";
import bg4 from "../Assets/images/contact2.JPG";
import bg5 from "../Assets/images/wr3.jpg";

const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 70vh;
  padding-left: 20%;
  padding-top: 10%;
  font-size: 50px;
  font-weight: 600;
  background-color: transparent;
  position: relative;
  background-size: cover;
  background-position: top center;
  transition: background-image 1s ease-in-out;

  @media (min-width: 768px) {
    padding: 5% 10%;
  }

  @media (min-width: 1024px) {
    padding: 5% 20%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
`;

const AnimatedObjectImage = styled.img`
  position: absolute;
  top: 50px;
  right: 10%;
  width: 400px;
  animation: moveObject 5s infinite linear;

  @media (max-width: 768px) {
    right: 10%;
    width: 250px;
  }

  @media (max-width: 1024px) {
    right: 20%;
    width: 300px;
  }
`;

const PageTitle = ({ title, AnimatedObject }) => {
  const [index, setIndex] = useState(0);

  const bgImages = [bg2, bg3, bg4, bg5];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % bgImages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <PageTitleWrapper style={{ backgroundImage: `url(${bgImages[index]})` }}>
      <Overlay />
      <AnimatedObjectImage src={AnimatedObject} alt="" />
      <div className="page-title">{title}</div>
    </PageTitleWrapper>
  );
};

export default PageTitle;

/*
import React from "react";
import styled from "styled-components";

const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20%;
  padding-top: 10%;
  font-size: 50px;
  font-weight: 600;
  background-color: transparent;
  position: relative;
  background-image: url("../../Assets/himg1.png");

  @media (min-width: 768px) {
    padding: 5% 10%;
  }

  @media (min-width: 1024px) {
    padding: 5% 20%;
  }
`;

const AnimatedObjectImage = styled.img`
  position: absolute;
  top: 50px;
  right: 10%;
  width: 400px;
  animation: moveObject 5s infinite linear;

  @media (max-width: 768px) {
    right: 10%;
    width: 250px;
  }

  @media (max-width: 1024px) {
    right: 20%;
    width: 300px;
  }
`;

const PageTitle = ({ title, AnimatedObject }) => {
  return (
    <PageTitleWrapper>
      <AnimatedObjectImage src={AnimatedObject} alt="Animated Object" />
      <div className="page-title">{title}</div>
    </PageTitleWrapper>
  );
};

export default PageTitle;
*/
