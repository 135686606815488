import React from "react";
import PageTitle from "../PageTitle";
import Ser1 from "../../Assets/bimg1.jpg";
import Ser2 from "../../Assets/bimg1.jpg";
import Ser3 from "../../Assets/bimg1.jpg";
import Ser4 from "../../Assets/bimg1.jpg";
import Press from "./Press";
import "./Services.css";
import { Divider } from "@mui/material";
import HSlider from "../HSlider";

const Services = () => {
  const serviceData = {
    title: "Service Client",
    text: `Nous comprenons l'importance d'une expérience client
    exceptionnelle. Notre équipe dédiée de professionnels du support
    client est là pour offrir une assistance de qualité supérieure à vos
    clients, renforçant ainsi votre image de marque et fidélisant votre
    clientèle.`,
    buttonText: "Apprendre Plus",
    imageUrl: Ser1,
    title2:
      "Plongez dans une expérience client exceptionnelle avec Collable. Notre équipe dévouée est prête à offrir un soutien de qualité supérieure à vos clients, renforçant ainsi votre réputation et favorisant la fidélité. Les avantages comprennent:",
    options: [
      "Réponse rapide et efficace aux besoins des clients.",
      "Assistance personnalisée et attentionnée.",
      "Gestion proactive des problèmes pour une résolution rapide.",
      "Suivi régulier pour garantir la satisfaction et la fidélisation des clients.",
    ],
  };

  const serviceData2 = {
    title: "Support Technique",
    text: `Nous comprenons l'importance d'une expérience client
    exceptionnelle. Notre équipe dédiée de professionnels du support
    client est là pour offrir une assistance de qualité supérieure à vos
    clients, renforçant ainsi votre image de marque et fidélisant votre
    clientèle.`,
    buttonText: "Apprendre Plus",
    imageUrl: Ser2,
    title2:
      "Faites confiance à notre équipe de support technique pour résoudre tous vos défis technologiques. Nous offrons une assistance proactive et réactive pour garantir le bon fonctionnement de vos systèmes. Nos avantages incluent :",
    options: [
      "Expertise technique avancée dans une variété de domaines.",
      "Réponses rapides et solutions efficaces pour minimiser les temps d'arrêt.",
      "Surveillance proactive des systèmes pour détecter et prévenir les problèmes.",
      "Accès à un support 24/7 pour une tranquillité d'esprit totale.",
    ],
  };

  const serviceData3 = {
    title: "Digitalisation D'entreprise",
    text: `Nous comprenons l'importance d'une expérience client
    exceptionnelle. Notre équipe dédiée de professionnels du support
    client est là pour offrir une assistance de qualité supérieure à vos
    clients, renforçant ainsi votre image de marque et fidélisant votre
    clientèle.`,
    buttonText: "Apprendre Plus",
    imageUrl: Ser3,
    title2:
      "Transformez votre entreprise avec notre expertise en digitalisation. De la création de stratégies numériques à la mise en œuvre de solutions innovantes, nous vous aidons à rester compétitif dans un monde numérique en évolution. Nos avantages comprennent :",
    options: [
      "Analyse approfondie des besoins et des défis spécifiques de votre entreprise.",
      "Développement de solutions sur mesure pour maximiser l'efficacité et la rentabilité.",
      "Formation et accompagnement pour assurer une transition en douceur vers les nouveaux processus numériques.",
      "Suivi et optimisation continus pour garantir des résultats durables et une croissance continue.",
    ],
  };

  const serviceData4 = {
    title: "RH et Comptabilité",
    text: `Nous comprenons l'importance d'une expérience client
    exceptionnelle. Notre équipe dédiée de professionnels du support
    client est là pour offrir une assistance de qualité supérieure à vos
    clients, renforçant ainsi votre image de marque et fidélisant votre
    clientèle.`,
    buttonText: "Apprendre Plus",
    imageUrl: Ser4,
    title2:
      "Faites confiance à Collable pour la gestion efficace de vos ressources humaines et de vos finances. Nous offrons des services personnalisés pour répondre à vos besoins spécifiques, garantissant une gestion transparente et efficace de vos ressources les plus précieuses. Nos avantages incluent :",
    options: [
      "Expertise en gestion des ressources humaines pour attirer, développer et retenir les meilleurs talents.",
      "Services comptables professionnels pour une gestion financière précise et transparente.",
      "Analyse approfondie des performances pour identifier les opportunités d'amélioration et maximiser la rentabilité.",
      "Conseils stratégiques personnalisés pour orienter votre entreprise vers le succès à long terme.",
    ],
  };

  return (
    <div className="services-container">
      <PageTitle title="Services" />

      <Press
        title={serviceData.title}
        text={serviceData.text}
        buttonText={serviceData.buttonText}
        imageUrl={serviceData.imageUrl}
        title2={serviceData.title2}
        options={serviceData.options}
      />
      <Divider />
      <Press
        title={serviceData2.title}
        text={serviceData2.text}
        buttonText={serviceData2.buttonText}
        imageUrl={serviceData2.imageUrl}
        title2={serviceData2.title2}
        options={serviceData2.options}
      />
      <Divider />
      <Press
        title={serviceData3.title}
        text={serviceData3.text}
        buttonText={serviceData3.buttonText}
        imageUrl={serviceData3.imageUrl}
        title2={serviceData3.title2}
        options={serviceData3.options}
      />
      <Divider />
      <Press
        title={serviceData4.title}
        text={serviceData4.text}
        buttonText={serviceData4.buttonText}
        imageUrl={serviceData4.imageUrl}
        title2={serviceData4.title2}
        options={serviceData4.options}
      />
      <Divider />
      <HSlider
        title="Notre Espace de Travail"
        text="Vous êtes assez motivé ! rejoignez notre équipe maintenant et construisez votre meilleure Carrière"
      />
    </div>
  );
};

export default Services;
