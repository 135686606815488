import React, { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  {
    /*  "https://collable.onrender.com/send-quote", `${process.env.REACT_APP_SERVER_URL}/send-quote` */
  }

  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://collable.onrender.com/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitted(true);
        setTimeout(() => {
          setFormData({ name: "", email: "", subject: "", message: "" });
          setSubmitted(false);
        }, 5000);
      } else {
        console.error("Failed to send email:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending email:", error.message);
    }
  };

  return (
    <div className="contact-form-container">
      <h2>Contactez-Nous</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Nom:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="subject">Sujet:</label>
        <input
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
        />

        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>

        <button className="secondary-button" type="submit">
          {submitted ? "Message envoyé !" : "Envoyer"}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
