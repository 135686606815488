import React from "react";

const AppsSection = () => {
  return (
    <div className="apps-section-container">
      <div className="bg-overlay">
        <div className="apps-section-text-container">
          <h1 className="img-section-heading">
            Plongez dans l'univers Collable et libérez le potentiel de votre
            entreprise.
          </h1>
          <p>
            Découvrez les possibilités infinies offertes par Collable et
            commencez votre voyage vers l'excellence aujourd'hui. Consultez nos
            solutions sur mesure et explorez d'avantage pour libérer le
            potentiel de votre entreprise.
          </p>

          <div className="apps-buttons-container">
            <button
              className="primary-button"
              onClick={() => {
                window.location.href = "mailto:Contact@groupecollable.com";
              }}
            >
              Consulter
            </button>
            <button
              className="secondary-button"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/sarl-collable/",
                  "_blank"
                )
              }
            >
              Voir Plus
            </button>
          </div>
        </div>
        <div className=" apps-section-image-container"></div>
      </div>
    </div>
  );
};

export default AppsSection;
