import React from "react";
import Resources from "../Assets/rhimg.jpg";
import Felexible from "../Assets/feximg.jpg";
import Infra from "../Assets/infraimg.jpg";
import Locallisation from "../Assets/geoimg.jpg";

const Work = () => {
  const workInfoData = [
    {
      image: Resources,
      title: "Ressources Humaines",
      text: "Compétences avancées, Stratégie de recrutement captivante, Formation continue.",
    },
    {
      image: Felexible,
      title: "Flexibilité ",
      text: "Multisectoriel Engagement à long terme Adaptabilité.",
    },
    {
      image: Infra,
      title: "Infrastructure de Pointe",
      text: "Connectivité haut débit de 300 Mbps, Système de secours, Technologie avancée.",
    },
    {
      image: Locallisation,
      title: "Avantages Géographique",
      text: "Proximité Géographique, Coûts compétitifs, Fuseau horaire favorable.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <h1 className="body-heading">Qu'est-ce qui nous différencie </h1>
        <p className="body-text">
          Chez Collable, nous offrons des solutions sur mesure pour répondre à
          vos besoins spécifiques. Notre engagement est de vous fournir des
          services de qualité supérieure, adaptés à votre entreprise.
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((service, index) => (
          <div
            className="work-card"
            key={index}
            style={{
              backgroundImage: `url(${service.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="overlay"></div>
            <h2>{service.title}</h2>
            <p>{service.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
