import React from "react";
import AboutBackground from "../Assets/images/bg6.png";
import AboutImage from "../Assets/bimg2.jpg";
import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutImage} alt="about" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">A Propos</p>
        <h1 className="body-heading">
          Un moyen simple et éprouvé d’améliorer les performances de votre
          équipe.
        </h1>
        <p>
          Bienvenue sur la page À Propos de Collable, où nous sommes fiers de
          vous présenter notre histoire, notre mission et notre engagement
          envers nos clients. Depuis notre création, nous nous efforçons de
          devenir le partenaire de confiance de nombreuses entreprises en
          offrant des services de qualité supérieure et des solutions innovantes
          pour répondre à leurs besoins diversifiés.
        </p>
        <p>
          Chez Collable, nous croyons fermement en la valeur de l'expertise
          humaine et de la technologie pour stimuler la croissance et assurer le
          succès à long terme. Notre équipe dévouée de professionnels qualifiés
          est passionnée par l'excellence et est déterminée à vous fournir un
          service personnalisé et attentif à chaque étape de notre
          collaboration.
        </p>
        <p>
          Nous nous engageons à maintenir les normes les plus élevées en matière
          d'éthique professionnelle, d'intégrité et de transparence dans toutes
          nos interactions avec nos clients. Notre objectif est de devenir non
          seulement votre prestataire de services, mais également votre
          partenaire stratégique, travaillant main dans la main pour atteindre
          vos objectifs commerciaux.
        </p>
        <div className="about-buttons-container">
          <button className="secondary-button">Voir Plus</button>
          <button className="watch-video-button">
            <BsFillPlayCircleFill /> Regarder
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
