import Work from "../Work";
import Team from "../Team";

import Appssection from "../Appssection";
import Hero from "../Hero";
import Hsection from "../Hsection";

import HServices from "../HServices";
import HSlider from "../HSlider";
import Subscribe from "../Subscribe";
import Quote from "../Quote/Quote";

const Home = () => {
  return (
    <div>
      <Hero />
      <Hsection />
      <HServices />
      <Appssection />
      <Work />
      <HSlider
        title="Notre Espace de Travail"
        text="Découvrez notre équipe dynamique et dévouée chez Collable. Multiculturelles et hautement qualifiées, nous mettons notre expertise au service de nos clients pour assurer une expérience exceptionnelle. Rencontrez nos talents et voyez comment nous sommes prêts à relever les défis de demain avec vous."
      />
      <Team />

      <Quote />
    </div>
  );
};

export default Home;
