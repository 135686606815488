import React from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";
import Man1 from "../Assets/m1.png";
import Man2 from "../Assets/m2.jpg";
import Man3 from "../Assets/m3.jpg";
import Man4 from "../Assets/m4.jpg";
import Man5 from "../Assets/m5.png";

const Testimonial = () => {
  const testimonialData = [
    {
      image: ProfilePic,
      text: "Le service client exceptionnel de Collable a vraiment rehaussé l'image de notre marque. Leur équipe dévouée assure une assistance rapide, laissant nos clients satisfaits à chaque fois. Hautement recommandé pour un soutien inégalé !",
      stars: 5,
      author: "David Doe",
    },
    {
      image: Man1,
      text: "Grâce aux solutions informatiques innovantes de Collable, notre entreprise reste en tête du jeu. Leur expertise en gestion de la technologie a rationalisé nos opérations, augmentant considérablement notre efficacité et notre productivité.",
      stars: 5,
      author: "John Doe",
    },
    {
      image: Man2,
      text: "Les services RH et comptables de Collable ont été essentiels à la croissance de notre entreprise. Leur équipe professionnelle gère tout de manière transparente, nous permettant de nous concentrer sur notre activité principale. Un partenaire de confiance en effet !",
      stars: 5,
      author: "Alvin Markod",
    },
    {
      image: Man3,
      text: "Les stratégies de vente et de marketing de Collable ont révolutionné notre approche. Leurs campagnes sur mesure et leur expertise numérique ont élargi notre portée, entraînant une augmentation des prospects et des conversions. Un changement majeur pour notre entreprise !",
      stars: 5,
      author: "Bob Morley",
    },
    {
      image: Man4,
      text: "Choisir Collable pour notre gestion financière était une décision judicieuse. Leurs pratiques comptables méticuleuses et leur analyse perspicace ont fourni des conseils inestimables, assurant notre stabilité financière et notre croissance. Hautement satisfait !",
      stars: 5,
      author: "Jacky Ben",
    },
    {
      image: Man5,
      text: "Depuis que nous travaillons avec Collable, notre entreprise a connu une transformation remarquable. Leurs services financiers ont rationalisé nos processus, nous permettant de prendre des décisions éclairées pour assurer notre succès à long terme. Une équipe compétente et fiable !",
      stars: 5,
      author: "Ronis Kalin",
    },
    // Add more testimonial data as needed
  ];

  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Témoignages</p>
        <h1 className="body-heading">Ce qu'ils disent</h1>
        <p className="body-text">
          Découvrez ce que nos clients disent de nous. Leurs témoignages
          reflètent notre engagement envers la qualité et le service client
          exceptionnel.
        </p>
      </div>

      <div className="testimonial-slider">
        {testimonialData.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <img src={testimonial.image} alt="" />
            <p>{testimonial.text}</p>
            <div className="testimonials-stars-container">
              {[...Array(testimonial.stars)].map((_, starIndex) => (
                <AiFillStar key={starIndex} />
              ))}
            </div>
            <h2>{testimonial.author}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
