import React from "react";
import Apropos from "../Apropos";
import Team from "../Team";
import PageTitle from "../PageTitle";
import HSlider from "../HSlider";
import Testimonial from "../Testimonial";
import AnimatedObject from "../../Assets/3dhand.png";

const About = () => {
  return (
    <div>
      <PageTitle title="A Propos" />
      <Apropos />
      <Team />
      <HSlider
        title="Notre Espace de Travail"
        text="Vous êtes assez motivé ! rejoignez notre équipe maintenant et construisez votre meilleure Carrière"
      />
      <Testimonial />
    </div>
  );
};

export default About;
