import React from "react";
import Logo from "../Assets/logo.png";

import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";

const Footer = () => {
  return (
    <div>
      <div className="footer-wrapper">
        <div className="footer-section-one">
          <div className="footer-logo-container">
            <img src={Logo} alt="" />
          </div>
        </div>
        <div className="footer-section-two">
          <div className="footer-section-columns">
            <span
              onClick={() => {
                window.location.href = "tel:+33187643149";
              }}
            >
              01 87 64 31 49
            </span>
            <span
              onClick={() => {
                window.location.href = "tel:+213552478722";
              }}
            >
              (+213) 552 47 87 22
            </span>

            <span
              onClick={() => {
                window.location.href = "mailto:Contact@groupecollable.com";
              }}
            >
              Contact@groupecollable.com
            </span>
            <div className="footer-icons">
              <SiLinkedin
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/sarl-collable/",
                    "_blank"
                  )
                }
              />
              <BsYoutube
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCWk835yEtHxvh-BnMtUK9RQ",
                    "_blank"
                  )
                }
              />
            </div>
          </div>

          <div className="footer-section-columns"></div>
        </div>
      </div>
      <div className="copyright">
        <p>Copyright 2024 COLLABLE </p>
      </div>
    </div>
  );
};

export default Footer;
/*
import React from "react";
import Logo from "../Assets/logo.png";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <div>
      <div className="footer-wrapper">
        <div className="footer-section-one">
          <div className="footer-logo-container">
            <img src={Logo} alt="" />
          </div>
          <div className="footer-icons">
            <BsTwitter />
            <SiLinkedin />
            <BsYoutube />
            <FaFacebookF />
          </div>
        </div>
        <div className="footer-section-two">
          <div className="footer-section-columns">
            <span>Aide</span>
            <span>Partager</span>
            <span>Carrières</span>
            <span>Témoignages</span>
            <span>Travail</span>
          </div>
          <div className="footer-section-columns">
            <span>+213 552478722</span>
            <span>Contact@groupecollable.com</span>
          </div>
          <div className="footer-section-columns">
            <span>Terms & Conditions</span>
            <span>Privacy Policy</span>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>Copyright 2024 COLLABLE </p>
      </div>
    </div>
  );
};

export default Footer;

*/
