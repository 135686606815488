import React, { useState } from "react";
import { motion } from "framer-motion";
import "./Quote.css";
import Waves from "./Waves";

const Quote = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    businessName: "",
    fonction: "",
    businessType: "",
    country: "",
    knownBy: "",
    message: "",
    acceptTerms: false,
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  {
    /*  "https://collable.onrender.com/send-quote", `${process.env.REACT_APP_SERVER_URL}/send-quote` */
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://collable.onrender.com/send-quote", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitted(true);
        setTimeout(() => {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            businessName: "",
            fonction: "",
            businessType: "",
            country: "",
            knownBy: "",
            message: "",
            acceptTerms: false,
          });
          setSubmitted(false);
        }, 5000);
      } else {
        console.error("Failed to send quote:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending quote:", error.message);
    }
  };

  return (
    <div className="main-container">
      <Waves />
      <div className="main-title">
        <h1>Demandez un devis maintenant!</h1>
      </div>
      <div className="body-container">
        <motion.div
          className="title-container"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2>Prêt à dynamiser votre entreprise ?</h2>
        </motion.div>

        <motion.div
          className="form-container"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h3>Commençons</h3>
            <form className="quote-form" onSubmit={handleSubmit}>
              <div className="form-group-2">
                <input
                  style={{ marginRight: "10px" }}
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Prénom"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Nom"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group-2">
                <input
                  style={{ marginRight: "10px" }}
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Téléphone"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />

                <input
                  type="text"
                  id="businessName"
                  name="businessName"
                  placeholder="Société"
                  value={formData.businessName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group-2">
                <input
                  type="text"
                  id="fonction"
                  name="fonction"
                  placeholder="Fonction"
                  value={formData.fonction}
                  onChange={handleChange}
                  required
                />

                <select
                  id="businessType"
                  name="businessType"
                  value={formData.businessType}
                  onChange={handleChange}
                  required
                >
                  <option value="">Secteur d'Activité</option>
                  <option value="Agriculture & Forestry">
                    Agriculture & Forestry
                  </option>
                  <option value="Broadband Internet/DSL & ISP">
                    Broadband Internet/DSL & ISP
                  </option>
                  <option value="Business Services">Business Services</option>
                  <option value="Cable / Satellite Providers / Pay TV">
                    Cable / Satellite Providers / Pay TV
                  </option>
                  <option value="Charitable / Not For Profit">
                    Charitable / Not For Profit
                  </option>
                  <option value="Direct Marketing Services">
                    Direct Marketing Services
                  </option>
                  <option value="Direct Response">Direct Response</option>
                  <option value="Energy & Utilities">Energy & Utilities</option>
                  <option value="Financial Services and Banks">
                    Financial Services and Banks
                  </option>
                  <option value="Government and Public Administration">
                    Government and Public Administration
                  </option>
                  <option value="Healthcare & Pharmaceutical">
                    Healthcare & Pharmaceutical
                  </option>
                  <option value="Insurance (Life, Health, Auto, Home)">
                    Insurance (Life, Health, Auto, Home)
                  </option>
                  <option value="Intermediate Agency">
                    Intermediate Agency
                  </option>
                  <option value="Local/Long Distance Wired Communications">
                    Local/Long Distance Wired Communications
                  </option>
                  <option value="Mail Order ">Mail Order</option>
                  <option value="Manufacturing / Goods">
                    Manufacturing / Goods
                  </option>
                  <option value="Media & Entertainment">
                    Media & Entertainment
                  </option>
                  <option value="Membership Services">
                    {" "}
                    Membership Services
                  </option>
                  <option value="Restaurants / Food Services">
                    {" "}
                    Restaurants / Food Services
                  </option>
                  <option value="Retail, E-retailers and Wholasale Trade">
                    Retail, E-retailers and Wholasale Trade
                  </option>
                  <option value="Technology & Consumer Electronics ">
                    Technology & Consumer Electronics
                  </option>
                  <option value="Transportation & Logistics">
                    Transportation & Logistics
                  </option>
                  <option value="Travel, Tourism and Accommodation ">
                    Travel, Tourism and Accommodation
                  </option>
                  <option value="Wireless / Mobile Telecommunications">
                    Wireless / Mobile Telecommunications
                  </option>
                  <option value="Autres">Autres</option>
                </select>
              </div>
              <div className="form-group-2">
                <select
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                >
                  <option value="">Choisissez un pays</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">
                    Bosnia and Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brazil">Brazil</option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cabo Verde">Cabo Verde</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo (Congo-Brazzaville)">
                    Congo (Congo-Brazzaville)
                  </option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czechia (Czech Republic)">
                    Czechia (Czech Republic)
                  </option>
                  <option value="Democratic Republic of the Congo">
                    Democratic Republic of the Congo
                  </option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Greece">Greece</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-Bissau">Guinea-Bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Holy See">Holy See</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia">Micronesia</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="North Korea">North Korea</option>
                  <option value="North Macedonia">North Macedonia</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestine State">Palestine State</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Vincent and the Grenadines">
                    Saint Vincent and the Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Korea">South Korea</option>
                  <option value="South Sudan">South Sudan</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-Leste">Timor-Leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States of America">
                    United States of America
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>

                <select
                  id="knownBy"
                  name="knownBy"
                  value={formData.knownBy}
                  onChange={handleChange}
                  required
                >
                  <option value="">Comment avez-vous connu Collable</option>
                  <option value="website">
                    Moteur de recherche, site Internet
                  </option>
                  <option value="publicité">
                    Publicité en ligne, brochure
                  </option>
                  <option value="Presse spécialisée, Analyste, Rapport financier">
                    Presse spécialisée, Analyste, Rapport financier
                  </option>
                  <option value="Evènement, Webinar">Evènement, Webinar</option>
                  <option value="Recommandation d’un client Collable">
                    Recommandation d’un client Collable
                  </option>
                  <option value="Réseaux sociaux">Réseaux sociaux</option>
                </select>
              </div>
              <div className="form-group-text">
                <textarea
                  className="text-input"
                  id="message"
                  name="message"
                  placeholder="Souhaitez-vous ajouter un message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <div className="form-group checkbox-group">
                <input
                  type="checkbox"
                  id="acceptTerms"
                  name="acceptTerms"
                  checked={formData.acceptTerms}
                  onChange={handleChange}
                  required
                />
                <label className="terms-text" htmlFor="acceptTerms">
                  J'accepte de recevoir des nouvelles pertinentes du secteur,
                  des rapports d'analystes, des livres blancs, des études de cas
                  et des informations sur les produits de Collable. Collable ne
                  partagera ni ne vendra jamais vos informations à des tiers.
                </label>
              </div>
              <button className="secondary-button" type="submit">
                {submitted ? "Demande Envoyée!" : "Envoyer"}
              </button>
            </form>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Quote;
