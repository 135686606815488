import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ContactForm from "./ContactForm";

const ContactSection = () => {
  const contactData = {
    phoneNumbers: ["01 87 64 31 49", "(+213) 552 47 87 22"],
    emails: ["Contact@groupecollable.com", ""],
    addresses: [
      "Cité Rachid Aouchiche, Bloc A, route de Boukhiama Tazeboujt Béjaïa.",
    ],
    mapLocation: { lat: 36.748606, lon: 5.03946 },
  };
  const offices = [
    { name: "Bejaia", coordinates: [36.748606, 5.03946], color: "red" },
    // we can add more ofices here
  ];
  const handleGoButtonClick = () => {
    // Create a Google Maps URL with the coordinates
    const googleMapsUrl = `https://maps.app.goo.gl/C7KHdwaZUhGbiGb39`;

    // Open the URL in a new tab
    window.open(googleMapsUrl, "_blank");
  };
  const customIcon = (color) => {
    return new L.Icon({
      iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
    });
  };

  return (
    <div className="contact-section">
      <h1 className="body-heading">
        Entrez en contact avec Collable : là où votre succès prend racine et
        s'épanouit.
      </h1>
      <div className="contact-sec1">
        <div className="contact-data">
          <h2>Coordonnées</h2>
          <div>
            <h3>Numéros de téléphone :</h3>
            <ul>
              {contactData.phoneNumbers.map((number, index) => (
                <li key={index}>{number}</li>
              ))}
            </ul>
          </div>
          <div>
            <h3>E-mails :</h3>
            <ul>
              {contactData.emails.map((email, index) => (
                <li key={index}>{email}</li>
              ))}
            </ul>
          </div>
          <div>
            <h3>Adresses :</h3>
            <ul>
              {contactData.addresses.map((address, index) => (
                <li key={index}>{address}</li>
              ))}
            </ul>
          </div>
        </div>

        <ContactForm />
      </div>

      <div className="map-section">
        <h2>Nos Emplacements</h2>
        <MapContainer
          center={offices[0].coordinates}
          zoom={6}
          style={{ height: "400px" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {offices.map((office, index) => (
            <Marker
              key={index}
              position={office.coordinates}
              icon={customIcon(office.color)}
            >
              {/* You can add a popup with the office name or any additional information */}
              {/* <Popup>{office.name}</Popup> */}
            </Marker>
          ))}
        </MapContainer>
        <button className="secondary-button" onClick={handleGoButtonClick}>
          Aller
        </button>
      </div>
    </div>
  );
};

export default ContactSection;
