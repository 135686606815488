import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import wr1 from "../Assets/images/wr1.jpg";
import wr2 from "../Assets/images/wr2.jpg";
import wr3 from "../Assets/images/wr3.jpg";
import wr4 from "../Assets/images/wr4.jpg";
import wr5 from "../Assets/images/wr5.jpg";

const PageSliderWrapper = styled.div`
  display: block;
  align-items: center;
  justify-content: flex-start;
  height: 65vh;
  background-color: transparent;
  position: relative;
  background-size: cover;
  background-position: right center;
  transition: background-image 1s ease-in-out;

  margin-top: 100px;
  padding: 5% 20%;

  @media (max-width: 900px) {
    padding: 35px 30px;
    height: 60vh;
  }

  @media (max-width: 1024px) {
    padding: 5% 55px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
`;

const HSlider = ({ title, text }) => {
  const [index, setIndex] = useState(0);

  const bgImages = [wr1, wr2, wr3, wr4, wr5];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % bgImages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <PageSliderWrapper style={{ backgroundImage: `url(${bgImages[index]})` }}>
      <Overlay />
      <div className="slid-content">
        <div className="slid-title">{title}</div>
        <div className="slid-text">{text}</div>
        <Link to={"/contact"}>
          <button className="secondary-button">Entrer en Contact</button>
        </Link>
      </div>
    </PageSliderWrapper>
  );
};

export default HSlider;
