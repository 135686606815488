import React from "react";
import styled from "styled-components";

import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";
import { IoArrowRedo } from "react-icons/io5";

import Planet from "../Components/3dObject/Planet";

const HeroContainer = styled.div`
  position: relative;
`;

const Hero = () => {
  return (
    <HeroContainer className="home-container">
      <Planet /> {/* Display the Planet component */}
      <div className="home-banner-container">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="primary-heading">
            <span className="gradient-text">Bienvenue, </span>
            <br></br>
            <TypeAnimation
              sequence={[
                "Bienvenue dans un monde dynamique.",
                1000,
                " Bienvenue dans un monde connecté.",
                2000,
                "Bienvenue dans un monde innovant.",
                2000,
              ]}
              wrapper="span"
              speed={50}
              repeat={Infinity}
              className="gradient-text-anim"
            />
          </h1>
          <p className="primary-text">
            Transformons ensemble vos défis en opportunités.
          </p>
          <button
            className="secondary-button"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/sarl-collable/",
                "_blank"
              )
            }
          >
            En Savoir Plus
          </button>
        </motion.div>
      </div>
    </HeroContainer>
  );
};

export default Hero;

/*
import React from "react";
import styled from "styled-components";

import { FiArrowRight } from "react-icons/fi";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";

const HeroContainer = styled.div`
  position: relative;
`;

const Hero = () => {
  return (
    <HeroContainer className="home-container">
      <div className="home-banner-container">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="primary-heading">
            <span className="gradient-text">Bienvenue, </span>
            <br></br>
            <TypeAnimation
              sequence={[
                "Chez Collable",
                1000,
                "Donner du Pouvoir à la Réussite",
                2000,
                "Votre 2eme Slogan ici pour une bonne... ",
                2000,
                "Votre 3eme Slogan ici pour une bonne...",
                2000,
              ]}
              wrapper="span"
              speed={50}
              repeat={Infinity}
              className="gradient-text-anim"
            />
          </h1>
          <p className="primary-text">
            Là Où l'Expertise Rencontre l'Excellence dans le Service Client, la
            Technologie et Bien Plus Encore !
          </p>
          <button
            className="secondary-button"
            onClick={() =>
              window.open("https://www.couverture-auto.fr/", "_blank")
            }
          >
            En Savoir Plus
            <FiArrowRight />{" "}
          </button>
        </motion.div>
      </div>
    </HeroContainer>
  );
};

export default Hero;
*/
